.ui-selected {
    background-color: blue;
}

.time-slots-row {
    height: 18px;
}

.time-slot-cell {
    padding: 5px !important;
}

.blocked {
    background-color: gray !important
}

/* .time-slot-cell:hover {
    background-color: lightsteelblue
} */

.time-slot-cell>.ant-card {
    text-align: left;
}

.ant-table-cell-row-hover {
    background-color: rgb(230, 230, 230) !important
}

.selected_slot {
    background-color: darkseagreen !important
}

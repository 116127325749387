@import url('https://fonts.googleapis.com/css?family=Roboto:400,500');

body {
  margin: 0;
}

.ant-layout-sider-children {
  // background-color: #ED7F6E !important
  // background-color: #dec3be !important
  // background-color: #f9b001 !important
  background-color: #ff008c !important
}

.ant-layout-sider-trigger {
  // background-color: #ED7F6E !important
  // background-color: #dec3be !important
  // background-color: #f9b001 !important
  background-color: #ff008c !important
}
